import { Search } from "@mui/icons-material";
import { Typography } from "@mui/material";
import React, { useState } from "react";
import { Tab, TabList as BaseTabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import styled, { css } from "styled-components";
import { default as BaseAreaInfoPannel } from "../../components/areaInfoPannel";
import AreaSearchDrawer from "../../components/sp/areaSearchDrawer";
import { default as BaseAreaSearchFab } from "../../components/sp/areaSearchFab";
import { default as BaseCompanyIndexSP } from "../../components/sp/companyIndexSP";
import { default as BaseContactSP } from "../../components/sp/contactSP";
import background from "../../images/background.png";
import closeImg from "../../images/close.svg";

const AreaSP = ({ aboutAreaInfo, ...props }) => {
  const [searchAreaDrawerOpen, setSearchAreaDrawaerOpen] = useState(false);
  const handleDrawerToggle = () => {
    setSearchAreaDrawaerOpen(!searchAreaDrawerOpen);
  };

  return (
    <>
      <MainDiv>
        {!searchAreaDrawerOpen ? (
          <AreaSearchFab handleClick={handleDrawerToggle}>
            <Search />
          </AreaSearchFab>
        ) : (
          <CloseAreaSearchFab handleClick={handleDrawerToggle}>
            <img src={closeImg} alt="closeImg" />
          </CloseAreaSearchFab>
        )}
        <AreaSearchDrawer
          handleClick={handleDrawerToggle}
          drawerOpen={searchAreaDrawerOpen}
        />
        <FirstDiv>
          <MapDiv>
            <Area
              src={aboutAreaInfo.areaImageSP}
              alt={aboutAreaInfo.areaName}
            />
            <AreaDescriptionDiv>
              <AreaNameText>{aboutAreaInfo.japaneseAreaName}</AreaNameText>
              <RepresentativeAreaText>
                {aboutAreaInfo.majorCityDecription}
              </RepresentativeAreaText>
              <AreaDescriptionText>
                {aboutAreaInfo.areaInfoDescriptionText}
              </AreaDescriptionText>
            </AreaDescriptionDiv>
          </MapDiv>
          <Tabs selectedTabClassName="is-selected">
            <TabList>
              <BusinessTab>商業</BusinessTab>
              <TrafficTab>交通</TrafficTab>
              <ClimateTab>気候</ClimateTab>
            </TabList>
            <TabPanelDiv>
              <TabPanel>
                <AreaInfoPannel
                  documentList={aboutAreaInfo.businessDocumentList}
                />
              </TabPanel>
              <TabPanel>
                <AreaInfoPannel
                  documentList={aboutAreaInfo.trafficDocumentList}
                />
              </TabPanel>
              <TabPanel>
                <AreaInfoPannel
                  documentList={aboutAreaInfo.climateDocumentList}
                />
              </TabPanel>
            </TabPanelDiv>
          </Tabs>
        </FirstDiv>
        <ContentsDiv>
          <ContentsAreaNameTextDiv>
            <ContentsAreaName>
              {aboutAreaInfo.japaneseAreaName}のビジネスモデル一覧
            </ContentsAreaName>
          </ContentsAreaNameTextDiv>
          {props.data.map((props) => {
            return <CompanyIndexSP key={props.node.id} {...props} />;
          })}
        </ContentsDiv>

        <ContactDiv>
          <ContactSP />
        </ContactDiv>
      </MainDiv>
    </>
  );
};

const AreaSearchFab = styled(BaseAreaSearchFab)`
  ${({ theme }) => css`
    position: fixed;
    right: 0;
    bottom: 0;
    margin-bottom: 10px;
    margin-right: 10px;
    z-index: 999;
    background-color: ${theme.colors.blue.primary};
    color: ${theme.colors.white.primary};
  `}
`;

const CloseAreaSearchFab = styled(BaseAreaSearchFab)`
  ${({ theme }) => css`
    position: fixed;
    right: 10px;
    bottom: 10px;
    z-index: 999;
    background-color: ${theme.colors.white.primary};
    color: ${theme.colors.blue.primary};
  `}
`;

const MainDiv = styled.div`
  padding: 0 5%;
  background-image: url(${background});
`;

const MapDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const AreaDescriptionDiv = styled.div`
  display: flex;
  flex-direction: column;
`;
const ContactDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
  padding-bottom: 40px;
`;

const AreaInfoPannel = styled(BaseAreaInfoPannel)`
  padding: 20px;
`;

const TabList = styled(BaseTabList)`
  margin: 0;
  padding: 0;
`;

const TabPanelDiv = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white.primary};
    height: 430px;
    width: 100%;
    border-radius: 0 10px 10px 10px;
    overflow: hidden;
  `}
`;

const BusinessTab = styled(Tab)`
  ${({ theme }) => css`
    background-color: ${theme.colors.yellow.primary};
    padding: 13px 35px;
    font-weight: ${theme.fonts.weight.bold};
    font-size: ${theme.fonts.size.sm};
    letter-spacing: ${theme.fonts.spacing.primary};
    color: ${theme.colors.white.primary};
    border-radius: 10px 10px 0 0;
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    ${theme.typography};
    cursor: pointer;
    &.is-selected {
      background: ${theme.colors.white.primary};
      color: ${theme.colors.yellow.primary};
      border-radius: 10px 10px 0 0;
    }
  `}
`;

const TrafficTab = styled(Tab)`
  ${({ theme }) => css`
    background-color: ${theme.colors.purple.primary};
    padding: 13px 35px;
    font-weight: ${theme.fonts.weight.bold};
    font-size: ${theme.fonts.size.sm};
    letter-spacing: ${theme.fonts.spacing.primary};
    color: ${theme.colors.white.primary};
    border-radius: 10px 10px 0 0;
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    ${theme.typography};
    cursor: pointer;
    &.is-selected {
      background: ${theme.colors.white.primary};
      color: ${theme.colors.purple.primary};
      border-radius: 10px 10px 0 0;
    }
  `}
`;

const ClimateTab = styled(Tab)`
  ${({ theme }) => css`
    background-color: ${theme.colors.blue.secondary};
    padding: 13px 35px;
    font-weight: ${theme.fonts.weight.bold};
    font-size: ${theme.fonts.size.sm};
    letter-spacing: ${theme.fonts.spacing.primary};
    color: ${theme.colors.white.primary};
    border-radius: 10px 10px 0 0;
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    ${theme.typography};
    cursor: pointer;
    &.is-selected {
      background: ${theme.colors.white.primary};
      color: ${theme.colors.blue.secondary};
      border-radius: 10px 10px 0 0;
    }
  `}
`;
const ContactSP = styled(BaseContactSP)`
  margin-right: calc(50% - 50vw);
  margin-left: calc(50% - 50vw);
  display: block;
  width: 100vw;
`;
const Area = styled.img`
  width: 100px;
  margin-right: 20px;
`;

const AreaNameText = styled(Typography)`
  ${({ theme }) => css`
    font-weight: ${theme.fonts.weight.bold};
    font-size: ${theme.fonts.size.xlsp};
    letter-spacing: ${theme.fonts.spacing.primary};
    color: ${theme.colors.black.primary};
  `}
`;
const RepresentativeAreaText = styled(Typography)`
  ${({ theme }) => css`
    font-weight: ${theme.fonts.weight.bold};
    font-size: ${theme.fonts.size.sm};
    letter-spacing: ${theme.fonts.spacing.primary};
    color: ${theme.colors.black.primary};
  `}
`;
const AreaDescriptionText = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.fonts.size.sm};
    letter-spacing: ${theme.fonts.spacing.primary};
    color: ${theme.colors.black.primary};
  `}
`;

const ContentsDiv = styled.div`
  margin-top: 40px;
`;

const CompanyIndexSP = styled(BaseCompanyIndexSP)`
  margin-bottom: 40px;
`;

const ContentsAreaNameTextDiv = styled.div`
  margin-bottom: 20px;
`;

const ContentsAreaName = styled(Typography)`
  ${({ theme }) => css`
    font-weight: ${theme.fonts.weight.bold};
    font-size: ${theme.fonts.size.xlsp};
    letter-spacing: ${theme.fonts.spacing.primary};
    color: ${theme.colors.black.primary};
    text-align: center;
  `}
`;

const FirstDiv = styled.div`
  padding-top: 20px;
`;

export default AreaSP;
