import { Typography } from "@mui/material";
import React from "react";

import { Tab, TabList as BaseTabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import styled, { css } from "styled-components";
import { default as BaseAreaInfoPannel } from "../../components/areaInfoPannel";
import Breadcrumbs from "../../components/breadcrumbs";
import CompanyIndex from "../../components/companyIndex";
import { default as BaseContact } from "../../components/contact";
import { getAreaMapComponent } from "../../helpers/areaInfo";
import topBackground from "../../images/background.png";

const Area = ({ aboutAreaInfo, ...props }) => {
  // pageContextからcrumbsを取り出す
  const {
    breadcrumb: { crumbs },
  } = props.pageContext;

  return (
    <>
      <TopDiv>
        <AreaDiv>
          <BreadcrumbsDiv>
            <Breadcrumbs crumbs={crumbs} crumbLabel={`エリアページ`} />
            <AreaMap>{getAreaMapComponent(aboutAreaInfo.areaName)}</AreaMap>
          </BreadcrumbsDiv>
          <Tabs selectedTabClassName="is-selected">
            <TabList>
              <BusinessTab>商業</BusinessTab>
              <TrafficTab>交通</TrafficTab>
              <ClimateTab>気候</ClimateTab>
            </TabList>
            <TabPanelDiv>
              <TabPanel>
                <AreaInfoPannel
                  documentList={aboutAreaInfo.businessDocumentList}
                />
              </TabPanel>
              <TabPanel>
                <AreaInfoPannel
                  documentList={aboutAreaInfo.trafficDocumentList}
                />
              </TabPanel>
              <TabPanel>
                <AreaInfoPannel
                  documentList={aboutAreaInfo.climateDocumentList}
                />
              </TabPanel>
            </TabPanelDiv>
          </Tabs>
        </AreaDiv>
        <ContentsMainDiv>
          <ContentsAreaName>
            {aboutAreaInfo.japaneseAreaName}のビジネスモデル一覧
          </ContentsAreaName>
          <ContentsDiv articleLength={props.data.length}>
            {props.data.map((props) => {
              return <CompanyIndex key={props.id} {...props} />;
            })}
          </ContentsDiv>
        </ContentsMainDiv>

        <ContactDiv>
          <Contact />
        </ContactDiv>
      </TopDiv>
    </>
  );
};

const TopDiv = styled.div`
  background-image: url(${topBackground});
  margin-right: calc(50% - 50vw);
  margin-left: calc(50% - 50vw);
  display: block;
  width: 100vw;
`;

const BreadcrumbsDiv = styled.div`
  ${({ theme }) => css`
    ${theme.typography};
  `}
`;

const AreaDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 50px;
  gap: 75px;
`;

const AreaMap = styled.div`
  width: 526px;
`;

const TabList = styled(BaseTabList)`
  margin: 0;
  padding: 0;
`;

const TabPanelDiv = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white.primary};
    height: 430px;
    width: 100%;
    border-radius: 0 10px 10px 10px;
    overflow: hidden;
  `}
`;

const BusinessTab = styled(Tab)`
  ${({ theme }) => css`
    background-color: ${theme.colors.yellow.primary};
    padding: 17px 58px;
    font-weight: ${theme.fonts.weight.bold};
    font-size: ${theme.fonts.size.base};
    letter-spacing: ${theme.fonts.spacing.primary};
    color: ${theme.colors.white.primary};
    border-radius: 10px 10px 0 0;
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    ${theme.typography};
    cursor: pointer;
    &.is-selected {
      background: ${theme.colors.white.primary};
      color: ${theme.colors.yellow.primary};
      border-radius: 10px 10px 0 0;
    }
  `}
`;

const TrafficTab = styled(Tab)`
  ${({ theme }) => css`
    background-color: ${theme.colors.purple.primary};
    padding: 17px 58px;
    font-weight: ${theme.fonts.weight.bold};
    font-size: ${theme.fonts.size.base};
    letter-spacing: ${theme.fonts.spacing.primary};
    color: ${theme.colors.white.primary};
    border-radius: 10px 10px 0 0;
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    ${theme.typography};
    cursor: pointer;
    &.is-selected {
      background: ${theme.colors.white.primary};
      color: ${theme.colors.purple.primary};
      border-radius: 10px 10px 0 0;
    }
  `}
`;

const ClimateTab = styled(Tab)`
  ${({ theme }) => css`
    background-color: ${theme.colors.blue.secondary};
    padding: 17px 58px;
    font-weight: ${theme.fonts.weight.bold};
    font-size: ${theme.fonts.size.base};
    letter-spacing: ${theme.fonts.spacing.primary};
    color: ${theme.colors.white.primary};
    border-radius: 10px 10px 0 0;
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    cursor: pointer;
    ${theme.typography};
    &.is-selected {
      background: ${theme.colors.white.primary};
      color: ${theme.colors.blue.secondary};
      border-radius: 10px 10px 0 0;
    }
  `}
`;

const AreaInfoPannel = styled(BaseAreaInfoPannel)`
  padding: 20px;
`;

const ContentsMainDiv = styled.div`
  margin-top: 70px;
`;

const ContentsAreaName = styled(Typography)`
  ${({ theme }) => css`
    font-weight: ${theme.fonts.weight.bold};
    font-size: ${theme.fonts.size.xl4};
    letter-spacing: ${theme.fonts.spacing.primary};
    color: ${theme.colors.black.primary};
    text-align: center;
  `}
`;
const ContentsDiv = styled.div`
  width: 1264px;
  display: grid;
  justify-content: center;
  ${(props) =>
    1 < props.articleLength ? `grid-template-columns: 1fr 1fr 1fr` : ""};
  margin: 0 auto;
  margin-top: 50px;
  gap: 70px;
  padding: 32px;
`;

const ContactDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 70px;
  padding-bottom: 70px;
`;

const Contact = styled(BaseContact)``;

export default Area;
