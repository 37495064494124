import { Fab } from "@mui/material";
import React from "react";
const AreaSearchFab = ({ className, children, handleClick }) => {
  return (
    <Fab className={className} onClick={handleClick}>
      {children}
    </Fab>
  );
};

export default AreaSearchFab;
