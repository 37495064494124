import { useLocation } from "@reach/router";
import { graphql } from "gatsby";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import React, { useEffect, useState } from "react";
import "react-tabs/style/react-tabs.css";
import IndexJsonLd from "../components/IndexjsonLd";
import LoadingCircular from "../components/loadingCircular";
import { getArticleTag, getInfoAboutTheArea } from "../helpers/areaInfo";
import { default as Seo } from "../layouts/head";
import Layout from "../layouts/pc";
import LayoutSP from "../layouts/sp";
import { default as AreaPC } from "../templates/pc/area";
import AreaSP from "../templates/sp/area";
import { AREA_NAME } from "../utils/Area";

const Area = ({ pageContext, data }) => {
  // if (process.env.GATSBY_COUNTDOWN) {
  //   navigate("/countdown");
  // }
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    setTimeout(() => setLoading(false), 50);
  }, []);

  const params = new URLSearchParams(location.search);
  const areaName = params.get("areaName")
    ? params.get("areaName")
    : AREA_NAME.JAPAN;
  const aboutAreaInfo = getInfoAboutTheArea(areaName);
  const breakpoints = useBreakpoint();
  const title = `${aboutAreaInfo.japaneseAreaName}エリアのビジネスモデル・Webメディア｜ロートリ 地方の面白いビジネスモデルを紹介`;
  useEffect(() => {
    const array = [];
    const articleTags = getArticleTag(aboutAreaInfo.areaName);
    data.allContentfulServiceIndex.edges.forEach((article) => {
      if (aboutAreaInfo.areaName === AREA_NAME.JAPAN)
        return array.push(article);
      article.node.tags.forEach((tag) => {
        console.log(articleTags);
        if (articleTags.includes(tag.slug)) {
          array.push(article);
          return;
        }
      });
    });
    setArticles(array);
  }, [aboutAreaInfo]);

  return (
    <>
      <Seo title={title} />
      <IndexJsonLd title={title} />
      {loading ? (
        <LoadingCircular />
      ) : breakpoints.sm ? (
        <LayoutSP>
          <AreaSP
            aboutAreaInfo={aboutAreaInfo}
            pageContext={pageContext}
            data={articles}
          />
        </LayoutSP>
      ) : (
        <Layout>
          <AreaPC
            aboutAreaInfo={aboutAreaInfo}
            pageContext={pageContext}
            data={articles}
          />
        </Layout>
      )}
    </>
  );
};

export const query = graphql`
  query {
    allContentfulServiceIndex(sort: { order: DESC, fields: createdAt }) {
      ...contentsData
    }
  }
`;

export default Area;
