import { Button, Drawer as BaseDrawer } from "@mui/material";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";
import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { getAreasSP, getPreviousArea } from "../../helpers/areaInfo";
import { AREA_NAME } from "../../utils/Area";
import AreaSelectButtonSP from "./areaSelectButtonSP";

const AreaSearchDrawer = ({ className, handleClick, drawerOpen }) => {
  const location = useLocation();
  const [areaNames, setAreaNames] = useState([]);
  const params = new URLSearchParams(location.search);
  const areaName = params.get("areaName")
    ? params.get("areaName")
    : AREA_NAME.JAPAN;

  useEffect(() => {
    setAreaNames(getAreasSP(areaName));
  }, [areaName]);

  const onClickBack = () => {
    const previousArea = getPreviousArea(areaName);
    navigate(`${location.pathname}?areaName=${previousArea.areaName}`);
  };
  return (
    <Drawer
      className={className}
      PaperProps={{ style: { width: "100%" } }}
      open={drawerOpen}
      onClose={handleClick}
      anchor="bottom"
    >
      <AreaSearchDiv>
        {areaName !== AREA_NAME.JAPAN && (
          <BackButton onClick={onClickBack}>戻る</BackButton>
        )}
        <AreaSearchButtonDiv>
          {areaNames.map((props) => {
            return (
              <AreaSelectButtonSP
                key={props.areaName}
                {...props}
                currentAreaName={areaName}
                gtmID={`area-select-button-sp`}
              />
            );
          })}
        </AreaSearchButtonDiv>
      </AreaSearchDiv>
    </Drawer>
  );
};

const AreaSearchDiv = styled.div`
  ${({ theme }) => css`
    padding: 0 20px;
    padding-top: 20px;
    padding-bottom: 85px;
    background-color: ${theme.colors.blue.primary};
    border-radius: 10px 10px 0 0;
  `}
`;

const AreaSearchButtonDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 160px);
  justify-content: center;
  grid-gap: 1rem;
`;

const BackButton = styled(Button)`
  ${({ theme }) => css`
    margin-bottom: 15px;
    background-color: ${theme.colors.white.primary};
    font-size: ${theme.fonts.size.sm};
    color: ${theme.colors.black.primary};
    letter-spacing: ${theme.fonts.spacing.primarySP};
    border-radius: 10px;
    &:hover {
      background-color: ${theme.colors.white.primary};
    }
  `}
`;

const Drawer = styled(BaseDrawer)`
  .MuiDrawer-paper {
    border-radius: 10px 10px 0 0;
  }
`;

export default AreaSearchDrawer;
