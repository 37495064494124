import React from "react";
// Breadcrumbのデフォルトのデザイン
import { Breadcrumb as BaseBreadcrumb } from "gatsby-plugin-breadcrumb";
import "gatsby-plugin-breadcrumb/gatsby-plugin-breadcrumb.css";
import styled, { css } from "styled-components";
const Breadcrumbs = (props) => {
  return (
    <Breadcrumb
      crumbs={props.crumbs}
      crumbSeparator=" > "
      crumbLabel={props.crumbLabel}
    />
  );
};

export default Breadcrumbs;

const Breadcrumb = styled(BaseBreadcrumb)`
  ${({ theme }) => css`
    font-size: ${theme.fonts.size.base};
    font-weight: ${theme.fonts.weight.bold};
    text-decoration: none;
    color: ${theme.colors.black.primary};
  `}
`;
