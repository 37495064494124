import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";

const AreaInfoPannel = ({ className, documentList }) => {
  const breakpoint = useBreakpoint();
  const [page, setPage] = useState(1);
  // TODO: renderされる前に初期化できる方法が見つかったら修正
  useEffect(() => {
    setPage(1);
  }, [documentList]);

  const contentsLength = documentList.length;

  const increment = () => {
    contentsLength <= page ? setPage(1) : setPage(page + 1);
  };
  const decrement = () => {
    page <= 1 ? setPage(contentsLength) : setPage(page - 1);
  };
  return (
    <div className={className}>
      {contentsLength !== 1 && (
        <PaginationDiv>
          <PageButton onClick={decrement}>
            <ArrowBackIosNew />
          </PageButton>
          <Pagination>{`${page}/${contentsLength}`}</Pagination>
          <PageButton onClick={increment}>
            <ArrowForwardIos />
          </PageButton>
        </PaginationDiv>
      )}
      <MainDiv>
        {breakpoint.sm ? (
          <>
            <DocumentTextSP>
              {contentsLength < page
                ? documentList[0].text
                : documentList[page - 1].text}
            </DocumentTextSP>
            <InfoImage
              src={
                contentsLength < page
                  ? documentList[0].image
                  : documentList[page - 1].image
              }
              alt={`${
                contentsLength < page
                  ? documentList[0].image
                  : documentList[page - 1].image
              }_info_image`}
            />
          </>
        ) : (
          /* TODO: renderされる前に初期化できる方法が見つかったら修正 */
          <>
            <DocumentText>
              {contentsLength < page
                ? documentList[0].text
                : documentList[page - 1].text}
            </DocumentText>
            <InfoImage
              src={
                contentsLength < page
                  ? documentList[0].image
                  : documentList[page - 1].image
              }
              alt={`${
                contentsLength < page
                  ? documentList[0].image
                  : documentList[page - 1].image
              }_info_image`}
            />
          </>
        )}
      </MainDiv>
    </div>
  );
};

const PageButton = styled(Button)`
  ${({ theme }) => css`
    background-color: ${theme.colors.green.primary};
    border-radius: 16px;
    color: ${theme.colors.white.primary};
    font-size: ${theme.fonts.size.xl2};

    &:hover {
      background-color: ${theme.colors.green.primary};
      box-shadow: none;
    }
  `}
`;

const PaginationDiv = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
`;

const Pagination = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.colors.black.primary};
    font-size: ${theme.fonts.size.xlsp};
    font-weight: ${theme.fonts.weight.bold};
  `}
`;

const MainDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DocumentText = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.colors.black.primary};
    font-size: ${theme.fonts.size.base};
    letter-spacing: ${theme.fonts.spacing.primary};
    margin: 10px;
    width: 400px;
  `}
`;

const DocumentTextSP = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.colors.black.primary};
    font-size: ${theme.fonts.size.sm};
    letter-spacing: ${theme.fonts.spacing.primary};
    margin: 10px;
    width: 100%;
  `}
`;

const InfoImage = styled.img`
  height: 230px;
`;

export default AreaInfoPannel;
